import * as React from 'react'
import './styles.scss'
import Header from './header'
import Footer from './footer'
import ProfPic from '../images/r2d2.jpg'

const portfolioItems = [
	{
		title: 'e-Learning Platform',
		sinopsis: 'Online web application made for local startup',
		desc: 'Made with React and MongoDB.',
		// img: '',
		repo: 'https://github.com/prashketh/eLearningPlatform',
		idx: 1,
	},
	{
		title: 'Spacestagram',
		sinopsis: `Search for and interact with images from NASA`,
		desc: 'Made with React and MaterialUI.',
		repo: 'https://github.com/prashketh/NASAImagesApp',
		live: 'https://prashkethnasa.netlify.app/',
		idx: 2,
	},
	{
		title: 'The Shoppies',
		sinopsis: 'Vote for your favourite movies',
		desc: 'Made with React and MaterialUI.',
		repo: 'https://github.com/prashketh/Shoppies',
		live: 'https://prashkethshopify.netlify.app/',
		idx: 3,
	},
	{
		title: 'Amazon Mini App',
		sinopsis: 'Software Design final project',
		desc: 'Mobile application for an online store.',
		// img: '',
		repo: 'https://github.com/prashketh/AmazonMiniApp',
		idx: 4,
	},
	{
		title: 'Spotify API Clone',
		sinopsis: 'Microservice architecture',
		desc:
			'Java Spring Boot based API used for 2 microservices (MongoDB and Neo4j) to communicate with each other to accomplish specific use-cases.',
		// img: '',
		repo: 'https://github.com/prashketh/SpotifyAPIClone',
		idx: 5,
	},
	{
		title: 'Simple Pokedex',
		sinopsis: 'Prelude into React',
		desc: 'React web application that reads from Pokemon API.',
		// img: '',
		repo: 'https://github.com/prashketh/PokedexStarter',
		idx: 6,
	},
	{
		title: 'Donkey Kong Arcade Game',
		sinopsis: 'A fun project done with a friend',
		desc:
			'JavaScript and Phaser based recreation of original Donkey Kong arcade game.',
		// img: '',
		repo: 'https://github.com/prashketh/DonkeyKongAmateur',
		idx: 7,
	},
]

function renderFolio() {
	return portfolioItems.map(
		({ title, sinopsis, idx, desc, repo, live, img }) => {
			return (
				<div key={'div' + idx} className='tab'>
					<input
						key={'index' + idx}
						type='checkbox'
						id={idx + title}
						className='tab-checkbox'
					/>
					<label key={'label' + idx} className='tab-label' for={idx + title}>
						{' '}
						<span key={'pTit' + idx} className='folio-title'>
							{title}{' '}
						</span>
						<span key={'dot' + idx} className='folio-title'>
							·{' '}
						</span>
						<span key={'pSinop' + idx} className='folio-sinopsis'>
							{sinopsis}
						</span>
					</label>
					<div key={'tab' + idx} class='tab-content'>
						{/*<img alt="nicasia" src={img}/>*/}
						{desc}
						<span className='repolinks' key={'repo' + idx}>
							<a key={'repoLink' + idx} href={repo}>
								Link to Github Repo
							</a>
						</span>
						{live ? (
							<span className='repolinks' key={'live' + idx}>
								<a key={'liveLink' + idx} href={live}>
									Check it live
								</a>
							</span>
						) : null}
					</div>
				</div>
			)
		}
	)
}

const ProjectsPage = () => {
	return (
		<main>
			<head>
				<title>Projects</title>
			</head>
			<meta
				name='viewport'
				content='width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0'
			/>
			<link
				rel='stylesheet'
				href='https://use.fontawesome.com/releases/v5.7.2/css/all.css'
				integrity='sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr'
				crossorigin='anonymous'
			/>
			<div className='boxWidth'>
				<Header />
				<div className='userText resumeContainer noBox projectMargin'>
					<h4 className='resumeTitle mt-3'>Projects</h4>
					<p></p>
					<p>These are a few of my projects!</p>
					<div className=''>{renderFolio()}</div>
				</div>
			</div>
			<Footer />
		</main>
	)
}

export default ProjectsPage
